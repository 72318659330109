import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../backend';
import { Injectable } from '@angular/core';

export interface Element {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Injectable({
  providedIn: 'root'
})
export class ZoneService {
  api = `${baseUrl}zones`;

  constructor(private http: HttpClient) { }

  public addZone(zone: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addZone`, zone).pipe(
      retry(3)
    );
  }
  public addPlage(zones: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addPlage`, zones).pipe(
      retry(3)
    );
  }
  public addChauffeur(zones: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addChauffeur`, zones).pipe(
      retry(3)
    );
  }
  public addStock(stock: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addStock`, stock).pipe(
      retry(3)
    );
  }
  public allChauffeur(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/allChauffeur`).pipe(
      retry(3)
    );
  }
  public deletePlage(id,id_zone): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/deletePlage/${id}/${id_zone}`).pipe(
      retry(3)
    );
  }
  public viewPlage(id: any): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/viewPlage/${id}`).pipe(
      retry(3)
    );
  }
  public viewTransport(id: any): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/viewTransport/${id}`).pipe(
      retry(3)
    );
  }
  public getZones(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getZones`).pipe(
      retry(3)
    );
  }
  public getZoness(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getZoness`).pipe(
      retry(3)
    );
  }
  public updateChauffeur(id: any, zone): Observable<any>{
    return this.http.put<any>(`${this.api}/updateChauffeur/${id}`, zone).pipe(
      retry(3)
    );
  }
  public addTransport(id: any, transport): Observable<any>{
    return this.http.post<any>(`${this.api}/addTransport/${id}`, transport).pipe(
      retry(3)
    );
  }
  public deleteChauffeur(id): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/deleteChauffeur/${id}`).pipe(
      retry(3)
    );
  }
  public updateZone(id: any, zone): Observable<any>{
    return this.http.put<any>(`${this.api}/updateZone/${id}`, zone).pipe(
      retry(3)
    );
  }
  public getZone(id): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getZone/${id}`).pipe(
      retry(3)
    );
  }
  public getChauffeur(id): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getChauffeur/${id}`).pipe(
      retry(3)
    );
  }

  public getZonesPlage(id:any): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getZonesPlage/${id}`).pipe(
      retry(3)
    );
  }

  public deleteZone(id): Observable<any>{
    return this.http.get<any>(`${this.api}/deleteZone/${id}`).pipe(
      retry(3)
    );
  }
   
  public deleteTransport(id): Observable<any>{
    return this.http.get<any>(`${this.api}/deleteTransport/${id}`).pipe(
      retry(3)
    );
  }
}
