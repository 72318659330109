import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, timer } from 'rxjs';
import { AddPlageComponent } from '../add-plage/add-plage.component';
import { AddTransportComponent } from '../add-transport/add-transport.component';
import { DeletePlageComponent } from '../delete-plage/delete-plage.component';
import { DeleteTransportComponent } from '../delete-transport/delete-transport.component';
import { ZoneService } from '../services/zone.service';
import { UpdateZoneComponent } from '../update-zone/update-zone.component';

@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.scss']
})
export class TransportComponent implements OnInit {

  subscription: Subscription;
  displayedColumns: string[] = ['zone','produit', 'minimum','maximum','immatriculation','prix','action'];
    constructor( @Inject(MAT_DIALOG_DATA) public data: any,private zoneService:ZoneService,private dialog: MatDialog){}
 

  dataSource = new MatTableDataSource<PeriodicElement>(null);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngAfterViewInit() {
    

  }
  ngOnInit(): void {
    this.subscription =  timer(0, 3000).subscribe(res => {
      this.getAllOrders();
    });
  
  }


  
  getAllOrders() {
    this.zoneService.viewTransport(this.data.id).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource <PeriodicElement> (data); //pass the array you want in the table
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      return data
    })}


    addPlage(id){
      this.dialog.open(AddTransportComponent, {
        width: '400px',
        height: '500px',
        data: {id: id}
      })
    }
   
    updateZone(id){
      this.dialog.open(UpdateZoneComponent, {
        width: '400px',
        height: '380px',
        data: {id: id}
      })
    }
    deleteTransport(id){
      this.dialog.open(DeleteTransportComponent, {
       
        data: {id: id}
      })
    }

}

export interface PeriodicElement {
  zone: string;
  produit: string;
  minimum: string;
  maximum: string;
  immatriculation: string;
  prix: string;
  action: string;
 
 
 
}


