import { Subscription, timer } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateRegionComponent } from './../update-region/update-region.component';
import { DeleteRegionComponent } from './../delete-region/delete-region.component';
import { RegionService } from './../../services/region.service';
import { AddRegionComponent } from './../add-region/add-region.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { PrintService } from 'src/app/services/print.service';
import { Region } from './region.models';

@Component({
  selector: 'app-all-region',
  templateUrl: './all-region.component.html',
  styleUrls: ['./all-region.component.scss']
})
export class AllRegionComponent implements OnInit{
   // regions: any[] = []; 
public region: Region[]
dataSource = new MatTableDataSource<PeriodicElement>(null);
subscription: Subscription;

@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort, { static: false }) sort: MatSort;

public settings: Settings;
constructor(public print: PrintService, private dialog: MatDialog,public appSettings:AppSettings,private regionService:RegionService) {
 this.settings = this.appSettings.settings; 
}

ngOnInit(): void {
  this.subscription =  timer(0, 3000).subscribe(res => {
     this.getRegion()
   });

}
getRegion(){
 this.regionService.getAllRegion().subscribe(res => {
   this.region = res;
 });
}
// getAllOrders() {
//   this.regionService.getAllRegion().subscribe((data: any) => {
//     this.dataSource = new MatTableDataSource <PeriodicElement> (data); //pass the array you want in the table
//     this.dataSource.sort = this.sort;
//     this.dataSource.paginator = this.paginator;
//     return data
//   })}

addRegion(){
  this.dialog.open(AddRegionComponent, {
  })
}
deleteRegion(id){
  this.dialog.open(DeleteRegionComponent, {
    data: {id: id}
   
  })
}
upadteRegion(id){
  this.dialog.open(UpdateRegionComponent, {
    data: {id: id}
   
  })
  }
}

export interface PeriodicElement {
  pays_id: string
  libelle: string
   
  }