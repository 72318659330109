import { Menu } from './menu.model';

export const verticalMenuItems = [ 
    new Menu (1, 'Tableau de bord', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'produit', '/produitCommande', null, 'view_module', null, false, 0), 
    new Menu (3, 'Commandes en attente', '/commandeAttente', null, 'view_module', null, false, 0), 
    new Menu (4, 'Commandes traitées', '/commandeTraite', null, 'view_module', null, false, 0), 
]

export const horizontalMenuItems = [ 
    new Menu (1, 'Tableau de bord', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'produit', '/produitCommande', null, 'view_module', null, false, 0), 
    new Menu (3, 'Commandes en attente', '/commandeAttente', null, 'view_module', null, false, 0), 
    new Menu (4, 'Commandes traitées', '/commandeTraite', null, 'view_module', null, false, 0), 
    // new Menu (5, 'DataTables', '/dataTables', null, 'view_module', null, false, 0), 
]



























