import { SnackBarService } from 'src/app/services/snack-bar.service';
import { CommandeService } from 'src/app/services/commande.service';
import { UserService } from 'src/app/services/user.service';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, timer, Observable } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-commande-panier',
  templateUrl: './commande-panier.component.html',
  styleUrls: ['./commande-panier.component.scss']
})
export class CommandePanierComponent implements OnInit{
  observer = new Observable()
  tabCommandes: []
  tableauObjetCommande= [
    {id: "",client: "", avance: "", livraison: "", nomProduit: "" , nomZone: "", quantite: "", zone_id: "",  montant: ""}
  ]
  prixToutesLesCommandes: number=0
  subscription: Subscription;
  displayedColumns: string[] = ['idProduit', 'nomClient', 'quantite','idZone','montantTotal'];
    constructor(
      public dialogRef: MatDialogRef<CommandePanierComponent>,
      private _snckBar: SnackBarService,
     @Inject(MAT_DIALOG_DATA) public data: any,
     private sharedService:SharedService,
     private commandeService: CommandeService,
     public userService: UserService,
    private dialog: MatDialog
    ){
     this.tabCommandes = JSON.parse((localStorage.getItem('tableauToAdd')))
    
    }
 

  dataSource = new MatTableDataSource<PeriodicElement>(null);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngAfterViewInit() {
    

  }
  ngOnInit(): void {
    this.subscription =  timer(0, 3000).subscribe(res => {
    });
    this.calculePrixTotalDesCommandes(this.tabCommandes)
   
  
  }

  calculePrixTotalDesCommandes(arrayCommandes){
    
      for (let i = 0; i < arrayCommandes.length; i++) {
        this.prixToutesLesCommandes += Number(arrayCommandes[i].montant) 
      }
    
  }

  retirerUnProduitDuPanier(idProduit, montantTotal){
    let tempoArray: any
    tempoArray = this.tabCommandes.filter((data: any) => data.id != idProduit  )
      
      this.tabCommandes= tempoArray
      localStorage.setItem("tableauToAdd", JSON.stringify(this.tabCommandes))

      this.sharedService.taille= this.tabCommandes.length
      this.prixToutesLesCommandes= Number(this.prixToutesLesCommandes) - Number(montantTotal) 
    }
  
  getAllOrders() {
    this.observer.subscribe(() => {
      this.dataSource = new MatTableDataSource <PeriodicElement> (this.tabCommandes); //pass the array you want in the table
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      return this.tabCommandes
    })}


  
    ajoutCommandeFinale(){
     this.tabCommandes = JSON.parse((localStorage.getItem('tableauToAdd')))
        const obj={
          panier: this.tabCommandes.filter((data: any)=> data.id != ""),
          user_id: this.userService.getUserDetails()._id,
          montantTotal: this.prixToutesLesCommandes
        }

        this.commandeService.addCommandes(obj).subscribe(()=>{
          console.log("Bonjour",obj)
          this._snckBar.openSnackBar("Commande Ajoutée avec succès!!", "Fermer");
          localStorage.removeItem("tableauToAdd")
          this.sharedService.taille=1
          this.dialogRef.close();
          localStorage.setItem("tableauToAdd", JSON.stringify(this.tableauObjetCommande))
        },
        (error)=>{
          console.log("Bonjour",error)

        })



      }
   
}

export interface PeriodicElement {
  idProduit: string;
  nomClient: string;
  quantite: string;
  idZone: string;
  montantTotal: string;
}