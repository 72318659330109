import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroupDirective, NgForm, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-add-produit',
  templateUrl: './add-produit.component.html',
  styleUrls: ['./add-produit.component.scss']
})
export class AddProduitComponent implements OnInit {
 
  selectedFiles?: FileList;
  selectedFileNames: string[] = [];

  progressInfos: any[] = [];
  categories: any[] = []
  message: string[] = [];

  previews: string[] = [];
  imageInfos?: Observable<any>;
  settings: Settings;
  granite = true;
  fileData: File = null;
  bam: boolean = false;
  etatPadding: boolean = true;
  constructor(private _snckBar: SnackBarService,public dialogRef: MatDialogRef<AddProduitComponent>,public userService:UserService,public appSettings:AppSettings,private fb: FormBuilder,private produitService: ProduitService) {
    this.settings = this.appSettings.settings;     
  }
  ngOnInit(): void {
   this.getCategories();
  }
  
  produitForm =  this.fb.group({
    unite: ['', []],
    societe: ['', []],
    categorie_id: ['', []],
    pays: ['', []],
    devise: ['', []],
    type: ['', []],
    zone: ['', []],
    ville: ['', []],
    prix: ['', [Validators.required, Validators.pattern(/^[0-9+]{1,}$/)]],
    image: [''],
    description: [''],
    distributeur_id : [''],
    status: [1],
    validation :[1]
  });

  select(event){
    if(event.target.value == 'GRANITE' ||event.target.value == 'BLOC' ||event.target.value == 'CIMENT'||event.target.value == 'GRAVIER' ||event.target.value == 'FER A BETON' ||event.target.value == 'SABLE'||event.target.value == 'LATERITE' ){
      this.granite = true;
    }else{
      this.granite = false;
    }
  }
  

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    if (fileInput.target.files.length > 0) {
      const file = fileInput.target.files[0];
      this.produitForm.get('image').setValue(file);
      this.bam = true;
    }
  }
  getCategories(){
    this.produitService.getCategorie().subscribe(res => {
      this.categories = res;
      console.log("mes categories",this.categories);
    });
  }
  onSubmit(){
    this.etatPadding = false;

    const formData = new FormData();
    formData.append('file', this.image.value);
    if(this.bam){
      this.produitService.upload(formData).subscribe(res => {
        this.image.setValue(res.path);
        this.distributeur_id.setValue(this.userService.getUserDetails()._id);

        this.produitService.addProduit(this.produitForm.value).subscribe(res => {
          console.log("mes formes",this.produitForm.value);
          this._snckBar.openSnackBar("Produit Ajouter!!", "Fermer");
          this.dialogRef.close();
          this.etatPadding = true;

        });
      })
    }else{
      this.distributeur_id.setValue(this.userService.getUserDetails()._id);
      this.produitService.addProduit(this.produitForm.value).subscribe(res => {
        this._snckBar.openSnackBar("Produit Ajouter!!", "Fermer");
        this.dialogRef.close();
        this.etatPadding = true;

      });
    }
    
  }

  getPrixError(): any {
    if (this.prix?.invalid && (this.prix?.dirty || this.prix?.touched)) {
      if (this.prix.errors?.required) {
        return 'Le Prix est requis!!';
      }else if(this.prix.errors?.pattern){
        return 'Le Prix doit etre un entier!!';
      }
    }
  }

  getPrixSuccess(): any {
    if (this.prix?.valid) {
      return true;
    }
  }
  
  get prix(){
    return this.produitForm.get('prix');
  }
  get categorie_id(){
    return this.produitForm.get('categorie_id');
  }
  get distributeur_id(){
    return this.produitForm.get('distributeur_id');
  }
  get image(){
    return this.produitForm.get('image');
  }

}
