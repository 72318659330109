import { Settings } from 'src/app/app.settings.model';
import { Component, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AppSettings } from 'src/app/app.settings';
import { RegionService } from 'src/app/services/region.service';

@Component({
  selector: 'app-datatablee',
  templateUrl: './datatablee.component.html',
  styleUrls: ['./datatablee.component.scss']
})
export class DatatableeComponent {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  editing = {};
  rows = [];
  temp = [];
  public region
  selected = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [
    { prop: 'name' },
    { name: 'pays_id'},
    { name: 'libelle' },
  ];
  public settings: Settings;

  constructor(
    public appSettings:AppSettings,
    private regionService: RegionService
    ) {
    this.settings = this.appSettings.settings; 
    this.regionService.getAllRegion()
      .subscribe(data=>{
        this.temp= [...data];
        this.rows = data;
        setTimeout(() => { this.loadingIndicator = false; }, 1500);
      })
  }

  fetch(data) {
    const req = new XMLHttpRequest();
    req.open('GET', 'assets/data/company.json');
    req.onload = () => {
      data(JSON.parse(req.response));
    };
    req.send();
  }

  public getAllRegion(){
    this.regionService.getAllRegion()
      .subscribe(data=>{
        this.region= data
      })
  }



  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function(d) {
      console.log(temp)
      return d.libelle.toLowerCase().indexOf(val) !== -1 || d.pays_id.libelle.toLowerCase().indexOf(val) !== -1 ||  !val;
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  updateValue(event, cell, rowIndex) {
    this.editing[rowIndex + '-' + cell] = false;
    this.rows[rowIndex][cell] = event.target.value;
    this.rows = [...this.rows];
  }

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onActivate(event) {
    console.log('Activate Event', event);
  }

}
