import { timer, Subscription } from 'rxjs';
import { CommandePanierComponent } from './../commande-panier/commande-panier.component';
import { Settings } from './../../app.settings.model';
import { PrintService } from './../../services/print.service';
import { Produit } from './../all-produit/Produit.model';
import { ProduitService } from './../../services/produit.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalCommandeComponent } from '../modal-commande/modal-commande.component';
import { Component, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/app.settings';
import { UserService } from 'src/app/services/user.service';
import { RoleService } from 'src/app/services/role.service';
import { SharedService } from 'src/app/services/shared.service';



@Component({
  selector: 'app-produit-commande',
  templateUrl: './produit-commande.component.html',
  styleUrls: ['./produit-commande.component.scss']
})
export class ProduitCommandeComponent implements OnInit{
  public produits: Produit[]
  public settings: Settings;
  prixToutesLesCommandes: number=0
  users: any[]; 
  tutorials:Produit[] = [];
  //currentTutorial: User = {};
  currentIndex = -1;
  title = '';
  
  page = 1;
  count = 0;
  pageSize = 3;
  pageSizes = [3, 6, 9];
  
   public searchText: string;
   
  subscription: Subscription;
  public taille: number
  tableauObjetCommande= [
    {id: "",client: "", avance: "", livraison: "", nomProduit: "" , nomZone: "", quantite: "", zone_id: "",  montant: ""}
  ]
  
  constructor(
     public print: PrintService,
     private sharedService: SharedService,
     private dialog: MatDialog,
     private produitService:ProduitService,
     public appSettings:AppSettings,
     public userService:UserService,
    public role: RoleService
     ){
    this.settings = this.appSettings.settings; 

    if(localStorage.getItem('tableauToAdd') == null){
      localStorage.setItem("tableauToAdd", JSON.stringify(this.tableauObjetCommande))
      this.tableauObjetCommande= JSON.parse(localStorage.getItem('tableauToAdd'))

   }
    else{
     this.tableauObjetCommande= JSON.parse(localStorage.getItem('tableauToAdd'))  
    }
  }
  
  ngOnInit(): void { 
    this.subscription =  timer(0, 3000).subscribe(res => {
    });
    this.retrieveTutorials();  
    
  }
  getTaille(){
    return this.sharedService.taille
  }

  calculePrixTotalDesCommandes(arrayCommandes){
    for (let i = 0; i < arrayCommandes.length; i++) {
             this.prixToutesLesCommandes += Number(arrayCommandes[i].montant) 
    }
  }
  viewCurrentCommandeInPanier(){
    this.dialog.open(CommandePanierComponent, {
      width: '800px',
      height: '200px',
    })
  }

  verifSiProduitEstDejaCommande(idProduit){
    this.tableauObjetCommande= JSON.parse(localStorage.getItem('tableauToAdd'))
    let filtredData= this.tableauObjetCommande.filter(data=> data.id= idProduit)
    if(filtredData.length > 0){
      return true
    }
    else{
      return false
    }
  }
  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};
  
    if (searchTitle) {
      params[`title`] = searchTitle;
    }
  
    if (page) {
      params[`page`] = page - 1;
    }
  
    if (pageSize) {
      params[`size`] = pageSize;
    }
  
    return params;
  }
  
  retrieveTutorials(): void {
    this.tutorials = null
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    
         
  
    this.produitService.getAllProduitByDistributeur(params.page).subscribe((res: any)=>{
        this.tutorials = res.docs;
        this.count = res.totalDocs;
        this.page = params.page;
        console.log("User bella",   this.page);
      })
  }
  
  handlePageChange(event: number): void {
    this.page = event;
    this.produitService.getAllProduitByDistributeur(this.page).subscribe((res: any)=>{
      this.tutorials = res.docs;
      this.count = res.totalDocs
    })
  }
  
  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    console.log("barry",this.pageSize);
    this.page = 1;
    this.retrieveTutorials();
  }
  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }
 /* getProduits(){
    this.produitService.getProduits().subscribe(res => {
      this.produits = res;
    });
}*/
  makeCommande(id: any){
    this.dialog.open(ModalCommandeComponent, {
      width: '520px',
      height: '600px',
      data: {id: id}
    })
  }






















  
}
