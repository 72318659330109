import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommandeService } from '../services/commande.service';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';
import { UserService } from '../services/user.service';
import { ZoneService } from '../services/zone.service';

@Component({
  selector: 'app-add-transport',
  templateUrl: './add-transport.component.html',
  styleUrls: ['./add-transport.component.scss']
})
export class AddTransportComponent implements OnInit {

 
  constructor(public dialogRef: MatDialogRef<AddTransportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private stockService:CommandeService,private distrubuteur:UserService,private produitService: ProduitService,private commandeService:CommandeService,private fb: FormBuilder, private zoneService: ZoneService, private _snackBar: SnackBarService) { }
  etatPadding: boolean = true;
  produits: any[] = [];
  users: any[] = [];
  zones: any[] = [];
  zone: any;
  zoneForm = this.fb.group({
    
    zone_id: ['', []],
    stock_id: ['', []],
    minimum: ['', []],
    maximum: ['', []],
    immatriculation: ['', []],
    prix: ['', []],
  });

  ngOnInit(): void {
  
    this.getProduits();
 this.getZones();
    this.distributeurs();
  }

  onSubmit(){
    this.etatPadding = false;
    
    this.zoneService.addTransport(this.data.id,this.zoneForm.value).subscribe(res => {
      this._snackBar.openSnackBar("Transport Ajouter!!", "Fermer");
     console.log("zone",this.zoneForm.value);
      this.etatPadding = true;
      
      this.dialogRef.close();
    })
   
  
  }
  getZones(){
    this.zoneService.getZones().subscribe(res => {
      this.zones = res;
      console.log("mes zones", this.zones)
    })
  }
  distributeurs()
  {
   this.distrubuteur.allUsersF().subscribe(res=>
     {
       this.users=res;
       console.log("Distributeur",this.users);
     })
 }

  get prix(){
    return this.zoneForm.get('prix');
  }
  get zone_id(){
    return this.zoneForm.get('zone_id');
  }
  get minimum(){
    return this.zoneForm.get('minimum');
  }
  get maximum(){
    return this.zoneForm.get('maximum');
  }

  get immatriculation(){
    return this.zoneForm.get('immatriculation');
  }
 
  get stock_id(){
    return this.zoneForm.get('stock_id');
  }
 
  getProduits(){
    this.stockService.allStock().subscribe(res => {
     this.produits = res;
     console.log("produit",this.produits);
    });
  }
  


}
