import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { publicUrl } from '../backend';
import { PrintService } from '../services/print.service';
import { UserService } from '../services/user.service';
import { emailOrTelValidators } from '../validators/email-or-telephone.validator';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  etatPadding: boolean = true;
  constructor(public dialogRef: MatDialogRef<UpdateComponent>,@Inject(DOCUMENT) private document: Document,public dialog: MatDialog,public print: PrintService, private fb: FormBuilder, private userService: UserService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }
  loginForm = this.fb.group({
    email: ['', {
      validators: [
       Validators.required,
     ],
      asyncValidators: [emailOrTelValidators(this.userService, 'login')]}
   ]
  })

  onSubmit(){
    this.etatPadding = false; 
    this.userService.reset(this.loginForm.value).subscribe(res => {
      console.log("bella",res._id);
      this.router.navigate(['/code', res._id]);
      Swal.fire(
        'Verifier vos messages!',
        'Nous venons d\'envoyer un code sur votre telephone.',
        'success'
      );
      this.etatPadding = true;
      this.dialogRef.close();
    })
  }

  getEmailError() {
    if (this.email.invalid && (this.email.dirty || this.email.touched)) {
      if (this.email.errors.required) {
        return 'Cet champs est requis!';
      }else if(this.email.errors.emailExist){
        return 'Cet telephone ou email n\'existe pas!';
      }else if(this.email.errors.email){
        return 'Email incorect!!';
      }
    }
  }

  getEmailSuccess() {
    if (this.email.valid) {
      return true;
    }
  }
  
  get email(){
    return this.loginForm.get('email');
  }


  
  onRedirect(){
    this.document.location.href = `${publicUrl}`;
  }


}
