import { ProduitCommandeComponent } from './../produit-commande/produit-commande.component';
import { CommandePanierComponent } from './../commande-panier/commande-panier.component';
import { ProduitService } from '../../services/produit.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Settings } from './../../app.settings.model';
import { PrintService } from './../../services/print.service';
import { Produit } from './../all-produit/Produit.model';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit,Inject } from '@angular/core';
import { AppSettings } from 'src/app/app.settings';
import { ZoneService } from './../../services/zone.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { RoleService } from 'src/app/services/role.service';
import { Observable, Subscription, timer } from 'rxjs';
import { RegionService } from 'src/app/services/region.service';
import { CommuneService } from 'src/app/services/commune.service';
import { SharedService } from 'src/app/services/shared.service';



@Component({
  selector: 'app-modal-commande',
  templateUrl: './modal-commande.component.html',
  styleUrls: ['./modal-commande.component.scss']
})
export class ModalCommandeComponent implements OnInit{
  public produits: Produit[]
  public settings: Settings;
  tableauObjetCommande= [
    {id: "", client: "" ,client_id: "",avance: "",livraison: "", nomProduit: "" , nomZone: "", quantite: "", zone_id: "",  montant: ""}
  ]
  values = 0;
  valu :any[];
  value :any[];
  livraisons = 0;
  Satatus_regions = 0;
  verifie_id =0;
  verifie_region  : any[] = [] ;;
  Satatus_commune = 0;
  Satatus_zone = 0;

  zoneCalcule = 0;
  reduction = 0;
  reductions: number = 0;
  restant = 0;
  horsZone = 1;
  zoneCalculeLivraison: any;
  plage : any[] = [] ;
  mappedData : any[] = [] ;
  plages : any[] = [] ;
  zoneCalculeLivraisons = 0;
  zoneCalculePlage = 0;
  info: any;
  bam: any;
  produit: any;
  zones: any[] = [];
  prod: any[] = [];
  zonss: any[] = [];
  regions: any[] = [];
  //panier: any[];
  etatPadding: boolean = true;
  etatPaddings: boolean = true;
  verify: boolean = true;
  quantiteIssupToPlage: boolean = true;
Verify1: boolean = true
Verify2: boolean = true
isLoading : boolean = false
//plages
max_plage : any[] = [];
test : any;
total : number = 0;
current_quantite : number = 0;
camions : any[] = [];
 montant : number = 0 ;
voyage : number = 0 ;
livraison: any = '';
payement: number = 0;
subscription: Subscription;
lieux: any;
componentPanier: ProduitCommandeComponent
  
  constructor(
     public print: PrintService,
     private sharedService: SharedService,
     private produitService:ProduitService,
     private communeService:CommuneService,
     public appSettings:AppSettings,
     public userService:UserService,
      private fb: FormBuilder,
      public dialogRef: MatDialogRef<ModalCommandeComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
       private zoneServices: ZoneService,
       private regionService: RegionService,
      public role: RoleService
     ){
      if(localStorage.getItem('tableauToAdd') == null){
        localStorage.setItem("tableauToAdd", JSON.stringify(this.tableauObjetCommande))
        this.tableauObjetCommande= JSON.parse(localStorage.getItem('tableauToAdd'))
        console.log("premiere Fois",this.tableauObjetCommande)
  
     }
      else{
       this.tableauObjetCommande= JSON.parse(localStorage.getItem('tableauToAdd'))  
      }
    this.settings = this.appSettings.settings; 
   
  }
  formGroup = this.fb.group({
    quantite: ['', [Validators.required, Validators.pattern(/^[0-9+]{1,}$/)]],
    client: ['', [Validators.required]],
    telephone: ['', [Validators.required]],
    zone_id: ['', []],
    livraison: [0,[Validators.required]],
    client_id: ['', []],
    produit_id: ['', []],
    marque: ['', []],
    date_commande: ['', []],
    categorie_id : [{nom: ""}, []],
    zone: ['', []],
    avance:['',[]],
    delais:['',[]],
    montantTotal:[0,[]],
    montant_payer:[0,[]],
    reste_payer:[0,[]],
    identifiant:[0,[]],
  });

  ngOnInit(): void {
    this.getRegion();

  }

  ajouterUnProduitAuPanier(idProduit: any, clientName: any, clientId: any,avance : any,livraison : any ,nomProduit: any, nomZone: any, qte : any, IdZone : any, prixCommande: any){
    const obj={
      id: idProduit,
      client: clientName,
      avance: avance,
      livraison: livraison,
      nomProduit: nomProduit,
      nomZone: nomZone,
      quantite: qte,
      zone_id: IdZone,
      montant: prixCommande,
      client_id: clientId
    }
    if(this.voirSiProduitEstChoisi(idProduit) == true){
      this.tableauObjetCommande.push(obj)
      localStorage.setItem('tableauToAdd',JSON.stringify(this.tableauObjetCommande))
      this.tableauObjetCommande= JSON.parse(localStorage.getItem('tableauToAdd'))  
      this.sharedService.taille= this.tableauObjetCommande.length
    }
    else{
     

    }

    

  }
 voirSiProduitEstChoisi(idProduit: any): Boolean{
    this.tableauObjetCommande = JSON.parse(localStorage.getItem('tableauToAdd'))
    let tab= this.tableauObjetCommande.filter(data=> data.id == idProduit)
    if(tab.length==0){
        return true;
    }
    else{
      return false;
    }
 }

  onSubmit(){
    this.etatPadding = false;
    if(this.livraison == 'Oui'){
      this.montantTotal.setValue(this.montant - this.reduction); 
      this.montant_payer.setValue(this.montant * this.payement - this.reduction );
      this.reste_payer.setValue(this.montant - this.montant * this.payement);
    }else if(this.livraison == 'Non'){
      this.montantTotal.setValue((this.values == 0 ? 1 : this.values) * this.zoneCalcule); 
      this.montant_payer.setValue((this.values == 0 ? 1 : this.values) * this.zoneCalcule );
    }
    this.produit_id.setValue(this.produit?._id);
    this.marque.setValue(this.produit._id.categorie_id?.nom);
    this.client_id.setValue(this.userService.getUserDetails()._id);
    this.ajouterUnProduitAuPanier(this.produit?._id,this.formGroup.value.client,this.userService.getUserDetails()._id,this.formGroup.value.avance,this.formGroup.value.livraison,this.produit?.categorie_id.nom,this.produit?.zone,this.formGroup.value.quantite,this.formGroup.value.zone_id,this.formGroup.value.montantTotal)
    this.dialogRef.close();
  }

  onChange(event: any){
    this.livraison = event.target.value;
  }

  onPayement(event: any){
    this.payement = event.target.value;
  }

  getRegion(){
    this.regionService.getAllRegion().subscribe(res => {
      this.regions = res;
    })
  }


  selectRegion(event: any){
   
   
    this.Satatus_regions = event.target.value;
    this.verifie_id = event.target.value;
   
    this.communeService.getAllCommuneByRegion(this.verifie_id).subscribe(res => {
      this.verifie_region= res;
   // this.getProduit();
    })
      
       // this.zoneCalcule = this.lieux[0].produit_id?.prix;    
  
  }

  onReduction(event: any){
    this.reduction = +<number>event.target.value;
  //  var y: number = +this.values;
  this.reductions = this.reduction;
    
  }

  selectCommune(event: any){
    this.Satatus_commune = event.target.value;
    this.regionService.getAllRegion().subscribe(res => {
      this.prod = res; 
    })
      
    this.produitService.getProduit(this.data.id).subscribe(res => {
      this.isLoading = true;
      this.produit = res;
      this.zones =  [];
      this.zoneServices.getZonesPlage(this.Satatus_commune).subscribe(res => {
      
        res.forEach(items => {
         this.zonss =  [];
          items.plage.forEach(item => {
               
            if(this.produit._id == item['produit_id']  ) {
              
              this.zonss.push(item);
                if( this.zonss.length == 1){
                  this.zones.push(items);
                  this.isLoading = false;
                }else{
                 
              }
             }
          
            
    
        });
  
      });
      
       
      })
    })  
    






   




  
  }

  selectZone(event: any){
    this.livraisons = event.target.value;
    this.lieux = this.zones.filter(result => {
      return result._id == event.target.value;
    }); 
    this.plage = this.lieux[0].plage;
    this.plages = this.lieux[0].prix;
      this.zoneCalcule = this.produit.prix;
  }

  onKey(event: any){
    this.max_plage =[];
    this.camions =[];
    this.values = 1;
    this.values = +<number>event.target.value;
    var y: number = +this.values;
    this.quantiteIssupToPlage = true;
    const nb = this.produit.prix;
    this.current_quantite = this.values;
    console.log("current",this.current_quantite);
    console.log("test",this.quantiteIssupToPlage);

 


  this.zonss =  [];
  this.plage.forEach(item => {
       
    if(this.produit._id == item['produit_id']  ) {
      
      this.zonss.push(item);
        if( this.zonss.length ==1){
         


          if(this.values >= this.plage[0].minimum ){
            for(let i = 0; i < this.plage.length;i++){  
              //quantite < a la plage
              if( this.current_quantite > 0 && this.current_quantite <= this.plage[i].maximum ){
                this.current_quantite = this.current_quantite - this.plage[i].maximum;
                this.quantiteIssupToPlage = false;
                this.camions.push(this.plage[i]);
              }
              //find the maximum
              else if(this.quantiteIssupToPlage){
                if(this.max_plage.length != 0 && this.plage[i].maximum > this.max_plage[0].maximum )
                {
                  this.max_plage = [];
                  this.max_plage.push(this.plage[i]);
                }else{
                  this.max_plage = [];
                  this.max_plage.push(this.plage[i]);
                }
               
              }
            }
        
            
            if(this.quantiteIssupToPlage && this.max_plage.length != 0)
            {
              
        
              const _camion = Math.floor(this.current_quantite / this.max_plage[0].maximum);
           
              this.current_quantite = Math.floor(this.current_quantite % this.max_plage[0].maximum);
              for(let i = 0 ;i < _camion ; i++)
              {
                this.camions.push(this.max_plage[0]);
        
              }
              console.log("camions",this.camions.length);
            }
        
        
            for(let i = 0; i < this.plage.length;i++){  
              //quantite < a la plage
              if( this.current_quantite > 0 && this.current_quantite <= this.plage[i].maximum ){
                this.current_quantite = this.current_quantite - this.plage[i].maximum;
                this.quantiteIssupToPlage = false;
                this.camions.push(this.plage[i]);
              }
              //find the maximum
              else if(this.quantiteIssupToPlage){
                if(this.max_plage.length != 0 && this.plage[i].maximum > this.max_plage[0].maximum )
                {
                  this.max_plage = [];
                  this.max_plage.push(this.plage[i]);
                }else{
                  this.max_plage = [];
                  this.max_plage.push(this.plage[i]);
                }
               
              }
            }
        
        
            // this.value = this.values
            for (let index = 0; index < this.camions.length; index++) {
              console.log("camions",this.camions[index].maximum);
              
              //console.log("total",this.total);
            }
            this.total= this.camions.reduce((acc, val) => acc += val.total, 0)
            console.log("total",this.total);
         this.voyage=this.camions.length;
         this.montant = this.total + this.values * nb ;
          }





        }else{
         
      }
     }
  
    

});



   
    
    
    const countryMap = {};
    
    this.camions.forEach(item => {
       countryMap[item.name] = [ ...( countryMap[item.name] || [] ), item];
    });
     this.mappedData = Object.entries(countryMap).map(entry => ({
      name: entry[0],
      nombre: entry[1]
   }));
   console.log("map",this.mappedData);

  }

  getclientSuccess() {
    if (this.client.valid) {
      return true;
    }
  }

  get client(){
    return this.formGroup.get('client');
  }
  get date_commande(){
    return this.formGroup.get('date_commande');
  }
  get marque(){
    return this.formGroup.get('marque');
  }
  get client_id(){
    return this.formGroup.get('client_id');
  }

  get produit_id(){
    return this.formGroup.get('produit_id');
  }

  get  montantTotal(){
    return this.formGroup.get('montantTotal');
  }
  get  montant_payer(){
    return this.formGroup.get('montant_payer');
  }
  get  avance(){
    return this.formGroup.get('avance');
  }
  get  zone(){
    return this.formGroup.get('zone');
  }
  get  zoness(){
    return this.formGroup.get('zoness');
  }
  get  reste_payer(){
    return this.formGroup.get('reste_payer');
  }
  get  identifiant(){
    return this.formGroup.get('identifiant');
  }
  getclientError() {
    if (this.client.invalid && (this.client.dirty || this.client.touched)) {
      if (this.client.errors.required) {
        return 'Le client est requis!!';
      }
    }
  }
  gettelephoneSuccess() {
    if (this.telephone.valid) {
      return true;
    }
  }

  get telephone(){
    return this.formGroup.get('telephone');
  }
 
  gettelephoneError() {
    if (this.telephone.invalid && (this.telephone.dirty || this.telephone.touched)) {
      if (this.telephone.errors.required) {
        return 'Le numero est requis!!';
      }
    }
  }
  getnomSuccess() {
    if (this.nom.valid) {
      return true;
    }
  }

  get nom(){
    return this.formGroup.get('nom');
  }
 
  getnomError() {
    if (this.nom.invalid && (this.nom.dirty || this.nom.touched)) {
      if (this.nom.errors.required) {
        return 'Le nom est requis!!';
      }
    }
  }
  getQuantiteError() {
    if (this.quantite.invalid && (this.quantite.dirty || this.quantite.touched)) {
      if (this.quantite.errors.required) {
        return 'La Quantitée est requise!!';
      }else if(this.quantite.errors.pattern){
        return 'La Quantitée doit etre un entier!!';
      }
    }
  }
  getQuantiteSuccess() {
    if (this.quantite.valid) {
      return true;
    }
  }
  get delais(){
    return this.formGroup.get('delais');
  }
  get quantite(){
    return this.formGroup.get('quantite');
  }

  get zone_id(){
    return this.formGroup.get('zone_id')
  }
}
