import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../backend';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProduitService {
  api = `${baseUrl}produits`;
  ap = `${baseUrl}categories`;
  constructor(private http: HttpClient) { }

  public addProduit(produit: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addProduit`, produit).pipe(
      retry(3)
    );
  }
  public rejeterProduit(produit: any): Observable<any>{
    return this.http.post<any>(`${this.api}/rejeterProduit`, produit).pipe(
      retry(3)
    );
  }
  public addCategorie(categorie: any): Observable<any>{
    return this.http.post<any>(`${this.ap}/addCategorie`, categorie).pipe(
      retry(3)
    );
  }
  public addBlog(categorie: any): Observable<any>{
    return this.http.post<any>(`${this.ap}/addBlog`, categorie).pipe(
      retry(3)
    );
  }
  public addSlider(categorie: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addSlider`, categorie).pipe(
      retry(3)
    );
  }
  public getEchantillon(id: any): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getEchantillon/${id}`).pipe(
      retry(3)
    );
  }
  public getActivechantillon(id: any): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getActivechantillon/${id}`).pipe(
      retry(3)
    );
  }
  public getDesactive(id: any): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getDesactive/${id}`).pipe(
      retry(3)
    );
  }
  public getValider(id: any): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getValider/${id}`).pipe(
      retry(3)
    );
  }
  public getInvalider(id: any): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getInvalider/${id}`).pipe(
      retry(3)
    );
  }
  public getActive(id: any): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getActive/${id}`).pipe(
      retry(3)
    );
  }
  public getProduitsBySociete(id:any): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getProduitsBySociete/${id}`).pipe(
      retry(3)
    );
  }
  public getProduitsByCategorie(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getProduitsByCategorie`).pipe(
      retry(3)
    );
  }
  public getAllProduitByDistributeur(page:any): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getAllProduitByDistributeur/${page}`).pipe(
      retry(3)
    );
  }
  public getProduits(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getAllProduitByDistributeur`).pipe(
      retry(3)
    );
  }
  public getCategorie(): Observable<any[]>{
    return this.http.get<any[]>(`${this.ap}/getCategorie`).pipe(
      retry(3)
    );
  }
  public getBlog(): Observable<any[]>{
    return this.http.get<any[]>(`${this.ap}/getBlog`).pipe(
      retry(3)
    );
  }
  public getSlider(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getSlider`).pipe(
      retry(3)
    );
  }
  public getSliderById(id): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getSliderById/${id}`).pipe(
      retry(3)
    );
  }
  public updateSlider(id: any, categorie): Observable<any>{
    return this.http.put<any>(`${this.api}/updateSlider/${id}`, categorie).pipe(
      retry(3)
    );
  }
  public getCategories(id: any): Observable<any>{
    return this.http.get<any>(`${this.ap}/getCategories/${id}`).pipe(
      retry(3)
    );
  }
  public getBlogById(id: any): Observable<any>{
    return this.http.get<any>(`${this.ap}/getBlogById/${id}`).pipe(
      retry(3)
    );
  }
  public deleteSlider(id: any): Observable<any>{
    return this.http.get<any>(`${this.api}/deleteSlider/${id}`).pipe(
      retry(3)
    );
  }
  public deleteCategorie(id: any): Observable<any>{
    return this.http.get<any>(`${this.ap}/deleteCategorie/${id}`).pipe(
      retry(3)
    );
  }
  public deleteBlog(id: any): Observable<any>{
    return this.http.get<any>(`${this.ap}/deleteBlog/${id}`).pipe(
      retry(3)
    );
  }
  public updateCategorie(id: any, categorie): Observable<any>{
    return this.http.put<any>(`${this.ap}/updateCategorie/${id}`, categorie).pipe(
      retry(3)
    );
  }
  public updateBlog(id: any, categorie): Observable<any>{
    return this.http.put<any>(`${this.ap}/updateBlog/${id}`, categorie).pipe(
      retry(3)
    );
  }
  public getProduit(id: any): Observable<any>{
    return this.http.get<any>(`${this.api}/getProduit/${id}`).pipe(
      retry(3)
    );
  }
  

  public updateProduit(id: any, produit): Observable<any>{
    return this.http.put<any>(`${this.api}/updateProduit/${id}`, produit).pipe(
      retry(3)
    );
  }

  public deleteProduit(id: any): Observable<any>{
    return this.http.get<any>(`${this.api}/deleteProduit/${id}`).pipe(
      retry(3)
    );
  }

  public upload(data) {
    return this.http.post<any>(`${this.api}/image`, data, {
      // reportProgress: true,
      // observe: 'events' 
    }).pipe(
      retry(3),
    );
  }
}
